var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.privacy == _vm.$enums.PRIVACY.All)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("$vuetify.icons.earth")])]}}],null,false,3053510900)},[_c('span',[_vm._v(_vm._s(_vm.$t("Public")))])]):_vm._e(),(_vm.privacy == _vm.$enums.PRIVACY.Members)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("$vuetify.icons.accountMultiple")])]}}],null,false,51376015)},[_c('span',[_vm._v(_vm._s(_vm.$t("Members")))])]):_vm._e(),(_vm.privacy == _vm.$enums.PRIVACY.Private)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("$vuetify.icons.lock")])]}}],null,false,1465742133)},[_c('span',[_vm._v(_vm._s(_vm.$t("Private")))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }