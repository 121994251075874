<template>
  <v-card height="180" outlined>
    <v-hover v-slot:default="{ hover }">
      <v-card flat :to="'/' + diary.name + '/album/media/' + image.id">
        <v-img :src="$imagehelpers.mediaWidth(image.image, 400)" height="180" class="grey lighten-2">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
          <div
            v-if="hover"
            class="grey darken-3 v-card--reveal white--text"
            style="
              height: 30px;
              margin-top: 150px;
              padding-left: 10px;
              padding-top: 5px;
            "
          >
            {{ image.title || $t("Untitled") }}
          </div>
        </v-img>
      </v-card>
    </v-hover>

    <media-menu
      v-on:deleted="onDeleted"
      v-on:updated="onUpdated"
      is-card
      :show-album-cover="showAlbumCover"
      :media="image"
    ></media-menu>
  </v-card>
</template>


<i18n>
    {
    "en": {
    "Untitled": "Untitled"
    },
    "sv": {
    "Untitled": "Titel saknas"
    }
    }
</i18n>



<script>
import { mapState } from "vuex";
import Menu from "./MediaItemMenu.vue";

export default {
  name: "AlbumImageItem",
  props: {
    image: {
      required: true,
    },
    showAlbumCover: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "media-menu": Menu,
  },
  data: () => ({
  }),
  methods: {
    onDeleted(media) {
      this.$emit("deleted", media.id);
    },
    onUpdated(data) {
      this.$emit("updated", data);
    },
  },
};
</script>