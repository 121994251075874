<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('EditInformation')"
    v-on:submit="saveInfo"
  >
    <template v-slot:content>
      <v-form ref="form" v-model="form" lazy-validation>
        <v-text-field
          :label="$t('Title')"
          :rules="[(v) => !!v || $t('TitleIsMissing')]"
          v-model="media.title"
          :disabled="is_saving"
          counter
          maxlength="40"
          outlined
          required
        ></v-text-field>
        <v-textarea
          :disabled="is_saving"
          no-resize
          outlined
          :placeholder="$t('Optional')"
          rows="3"
          v-model="media.description"
          :label="$t('Description')"
        ></v-textarea>

        <error-box :error="error"></error-box>
      </v-form>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "EditInformation": "Edit information",
    "Title": "Title",
    "TitleIsMissing": "Title is missing!",
    "Description": "Description",
    "InfoSaved": "Information updated!",
    "Optional": "Optional"
    },
    "sv": {
    "EditInformation": "Redigera information",
    "Title": "Titel",
    "TitleIsMissing": "Titel saknas!",
    "Description": "Beskrivning",
    "InfoSaved": "Informationen sparades!",
    "Optional": "Frivillig"
    }
    }
</i18n>

<script>
import { albumsService } from "../../../_services";
import { mapActions } from "vuex";

export default {
  name: "media_info_edit_dialog",
  computed: {},
  data: () => ({
    error: null,

    media: {
      title: "",
      description: "",
    },

    is_open: false,
    is_saving: false,
    form: false,
  }),
  created: function () {},
  methods: {
    ...mapActions({
      deletePhoto: "diary/albums/deleteMedia",
    }),
    async deleteMedia() {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteMedia"));

      if (res) {
        self.is_loading = true;

        self
          .deletePhoto(self.media.id)
          .then(function () {
            self.$successNoty(self.$t("SuccessDelete"));

            self.$router.push(
              "/" + self.diary.name + "/album/" + self.media.album.url_name
            );
            return;
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            self.is_loading = false;
          });
      }
    },
    editMedia(media) {
      this.media = { ...media };
      this.is_open = true;
    },

    saveInfo() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        albumsService
          .updateMedia(self.media.id, self.media)
          .then(function () {
            self.$successNoty(self.$t("InfoSaved"));
            self.is_saving = false;
            self.$emit("updated", self.media);
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
  },
};
</script>