<template>
  <span>
    <form-dialog
      v-model="is_open"
      :title="$t('RotateImage')"
      v-on:submit="save"
      max-width="550px"
      v-on:close="closing"
      :is-saving="is_saving"
      :hide-submit="error"
      :submitDisabled="!file || is_processing"
    >
      <template v-slot:content>
        <v-card
          outlined
          height="225"
          width="225"
          class="mx-auto mb-3"
          v-if="!error"
        >
          <v-img
            :src="temp"
            v-if="temp"
            contain
            height="225"
            width="225"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-overlay :value="is_loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>

        <div class="text-center">
          <v-btn @click="rotate" :loading="is_processing" outlined><v-icon>$vuetify.icons.rotate3d</v-icon></v-btn>
        </div>

        <error-box :error="error"></error-box>
      </template>
    </form-dialog>
  </span>
</template>

<i18n>
    {
    "en": {
    "RotateImage": "Rotate picture",
    "PhotoSaved": "Photo saved!"
    },
    "sv": {
    "RotateImage": "Rotera bild",
    "PhotoSaved": "Bilden sparades!"
    }
    }
</i18n>

<script>
import { mapActions } from "vuex";
import { albumsService } from "../../../_services";
export default {
  name: "rotate_image_dialog",
  props: {
    image: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    is_open: false,
    error: null,
    is_saving: false,
    is_loading: false,
    is_processing : false,

    temp: null,
    file: null,
  }),
  methods: {
    ...mapActions({}),
    open() {
      this.error = null;
      this.is_open = true;
      this.temp = this.image.original;
    },
    rotate() {
      // create an off-screen canvas
      this.is_processing = true;
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var self = this;
      var image = new Image();
      image.src = self.temp;
      image.crossOrigin = "anonymous";

      image.onload = function () {
        canvas.width = image.height;
        canvas.height = image.width;
        ctx.rotate((90 * Math.PI) / 180);
        ctx.translate(0, -canvas.width);
        ctx.drawImage(image, 0, 0);

        canvas.toBlob(function (blob) {
          self.temp = canvas.toDataURL();
          self.file = blob;
          self.is_processing = false;
        }, "image/jpeg");
      };
    },

    save() {
      var self = this;

      self.is_saving = true;
      albumsService
        .uploadEditedImage(self.id, self.file)
        .then(function (response) {
          self.is_saving = false;
          self.file = null;
          self.$emit("modified", response.data.image);
          self.$successNoty(self.$t("PhotoSaved"));
        })
        .catch(function (error) {
          self.is_saving = false;
          self.$ajaxErrorNoty(error);
        });
    },
    closing() {
      this.error = null;
      this.is_open = false;
      this.$emit("closed");
    },
  },
};
</script>