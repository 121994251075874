<template>
  <span>
    <form-dialog
      v-model="is_open"
      :title="$t('Banner')"
      v-on:submit="save"
      max-width="1100px"
      :submit-disabled="banner_processing || is_downloading"
      v-on:close="closing"
      :is-saving="is_saving"
    >
      <template v-slot:content>
        <v-card outlined class="grey lighten-2 mx-auto mb-3">
          <v-img
            :src="banner"
            v-if="banner"
            :height="$bannerHeight"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-sheet
            :height="$bannerHeight"
            color="grey lighten-3"
            v-if="!banner"
          >
            <v-row
              class="fill-height ma-0 text-center"
              align="center"
              justify="center"
            >
              <div>
                <h2>{{ $t("NoImageSelected") }}</h2>
              </div>
            </v-row>
          </v-sheet>

          <v-fade-transition>
            <v-overlay v-if="banner_processing" absolute color="#036358">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-fade-transition>
        </v-card>

        <div class="caption text-center pt-1 mb-3">{{ $t("NoteSize") }}</div>
        <div class="text-center">
          <v-btn
            outlined
            @click="pickfile"
            v-if="show_select"
            :loading="banner_processing"
            :disabled="is_saving || is_downloading"
            small
            class="mr-2 mb-2 text-none"
          >
            <v-icon small left>$vuetify.icons.plus</v-icon>
            {{ $t("SelectImage") }}
          </v-btn>
          <v-btn
            v-if="diary && showAlbum"
            outlined
            @click="openAlbum"
            :loading="is_downloading"
            :disabled="is_saving || banner_processing"
            small
            class="mr-2 mb-2 text-none"
          >
            <v-icon small left>$vuetify.icons.folderMultipleImage</v-icon>
            {{ $t("Album") }}
          </v-btn>
          <v-btn
            v-if="showGallery"
            outlined
            @click="openGallery"
            :loading="is_downloading"
            :disabled="is_saving || banner_processing"
            small
            class="mr-2 mb-2 text-none"
          >
            <v-icon small left>$vuetify.icons.imageSearchOutline</v-icon>
            {{ $t("Gallery") }}
          </v-btn>
          <v-btn
            v-if="!$isMobile"
            outlined
            @click="editBanner"
            :disabled="is_saving || banner_processing || is_downloading"
            small
            class="mr-2 mb-2 text-none"
          >
            <v-icon small left>$vuetify.icons.pencil</v-icon>
            {{ $t("EditImage") }}
          </v-btn>
          <v-btn
            outlined
            @click="deleteBanner"
            :disabled="is_saving || banner_processing || is_downloading"
            small
            color="red"
            v-if="banner && show_delete"
            class="mb-1 text-none"
            >{{ $t("DeleteImage") }}</v-btn
          >
        </div>
        <input
          type="file"
          style="display: none"
          ref="image"
          accept="image/*"
          v-on:change="onFilePicked"
        />

        <error-box :error="error" class="mt-3"></error-box>
      </template>
    </form-dialog>
    <media-picker
      v-if="diary"
      v-on:done="selectedMedia"
      ref="mediaPicker"
      :diary="diary.name"
      :max="1"
    ></media-picker>
    <image-gallery-picker
      v-on:done="selectedGalleryImage"
      ref="galleryPicker"
      :max="1"
    ></image-gallery-picker>
    <photo-editor
      v-on:close="onEditClose"
      v-on:done="onEditDone"
      v-if="show_editor"
      :image="main_banner"
    ></photo-editor>
  </span>
</template>

<i18n>
    {
    "en": {
    "Banner": "Banner",
    "SelectImage": "Select image",
    "DeleteImage": "Delete",
    "EditImage": "Editor",
    "BannerDeleted": "Banner deleted!",
    "BannerUploaded": "Banner uploaded!",
    "Album": "My albums",
    "Gallery": "Image gallery",
    "NoImageSelected": "No image selected!",
    "NoteSize": "Note: Best size of banner is 1200 x 300 px."
    },
    "sv": {
    "Banner": "Omslagsbild",
    "SelectImage": "Välj bild",
    "DeleteImage": "Ta bort",
    "EditImage": "Bildeditor",
    "BannerDeleted": "Omslagsbild togs bort!",
    "BannerUploaded": "Omslagsbild sparades!",
    "Album": "Mina album",
    "Gallery": "Bildgalleri",
    "NoImageSelected": "Ingen bild har valts!",
    "NoteSize": "Bästa storleken på omslagsbilden är 1200 x 300 px."
    }
    }
</i18n>

<script>
import { mapActions } from "vuex";
import MediaPicker from "../diary/dialogs/MediaPickerDialog.vue";
import ImageGalleryPickerDialog from "../diary/dialogs/ImageGalleryPickerDialog.vue";
import Compressor from "compressorjs";
const PhotoEditor = () => import("../global/PhotoEditor.vue");

export default {
  name: "banner_dialog",
  props: {
    showGallery: {
      type: Boolean,
      required: false,
    },
    showAlbum: {
      type: Boolean,
      required: false,
    },
  },

  components: {
    "media-picker": MediaPicker,
    "image-gallery-picker": ImageGalleryPickerDialog,
    "photo-editor": PhotoEditor,
  },
  data: () => ({
    is_open: false,
    error: null,
    is_saving: false,

    banner_processing: false,
    is_downloading: false,

    banner: null,
    main_banner: null,
    file: null,
    image_id: null,

    banner_type: null,

    user: null,
    diary: null,

    show_editor: false,
    show_select: true,
    show_delete: true,
  }),
  methods: {
    ...mapActions({
      uploadBannerToDb: "diary/uploadBanner",
      deleteBannerFromDb: "diary/deleteBanner",
    }),

    closing() {
      this.error = null;
      this.is_saving = false;
      this.banner_processing = false;
      this.is_downloading = false;
      this.banner = null;
      this.main_banner = null;
      this.file = null;
      this.show_editor = false;
      this.image_id = null;
    },

    openDiaryBanner(diary) {
      this.diary = diary;

      if (this.diary.banner) {
        this.banner = this.getBanner(this.diary.banner);
        this.main_banner = this.diary.banner.original;
      }

      this.banner_type = "diary";
      this.is_open = true;
    },
    openDiaryBannerByUrl(diary, url, imageId) {
      this.openDiaryBanner(diary);
      this.banner = null;
      this.main_banner = null;
      this.show_select = false;
      this.show_delete = false;
      this.image_id = imageId;
      this.selectByUrl(url);
    },

    getBanner(image) {
      if (typeof image === "object")
        return this.$imagehelpers.diaryBanner(image);

      if (image.indexOf("blob") > -1) return image;

      return this.$imagehelpers.diaryBanner(image);
    },
    pickfile() {
      this.$refs.image.click();
    },
    openGallery() {
      this.$refs.galleryPicker.open();
    },
    onFilePicked(e) {
      const file = e.target.files[0];
      this.image_id = null;
      this.compressImage(file);
    },
    compressImage(file) {
      var sender = this;

      if (!file) {
        return;
      }

      sender.banner_processing = true;
      sender.error = null;

      new Compressor(file, {
        quality: 0.9,
        minWidth: 1200,
        minHeight: 100,
        maxWidth: 1200,
        success(result) {
          var urlCreator = window.URL || window.webkitURL;
          var img = urlCreator.createObjectURL(result);

          sender.banner = img;
          sender.main_banner = img;
          sender.file = result; // this is an image file that can be sent to server...
          sender.banner_processing = false;
        },
        error(err) {
          sender.error = err;
          sender.banner_processing = false;
        },
      });
    },
    deleteBanner() {
      this.file = null;
      this.banner = null;
      this.main_banner = null;
      this.image_id = null;
    },

    editBanner() {
      this.show_editor = true;
    },
    onEditDone(data) {
      this.compressImage(data.blob);
      this.show_editor = false;
    },
    onEditClose() {
      this.show_editor = false;
    },
    openAlbum() {
      this.$refs.mediaPicker.open();
    },
    selectedMedia(items) {
      if (items.length > 0) {
        var img = items[0];
        this.selectByUrl(img.image.original);
        this.image_id = img.id;
      }
    },
    selectedGalleryImage(url) {
      if (!url) return;
      this.selectByUrl(url);
      this.image_id = null;
    },
    selectByUrl(url) {
      var self = this;

      self.is_downloading = true;
      self.error = null;

      this.$imagehelpers
        .getImageFormUrl(url)
        .then(function (response) {
          self.compressImage(response);

          self.is_downloading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_downloading = false;
        });
    },
    save() {
      var self = this;
      self.error = null;

      if (this.banner_type == "diary") {
        this.saveDiaryBanner();
      }
    },
    saveDiaryBanner() {
      var self = this;

      if (self.file) {
        self.is_saving = true;

        self
          .uploadBannerToDb({ file: self.file, image_id: self.image_id })
          .then(function () {
            self.is_open = false;
            self.is_saving = false;
            self.$successNoty(self.$t("BannerUploaded"));
            self.$emit("uploaded");
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      } else if (!self.banner && self.diary.banner != null) {
        self.is_saving = true;
        self
          .deleteBannerFromDb()
          .then(function () {
            self.is_open = false;
            self.is_saving = false;
            self.$successNoty(self.$t("BannerDeleted"));
            self.$emit("deleted");
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
  },
};
</script>