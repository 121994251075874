<template>
  <div v-if="user && menu && menu.length > 0 && media">
    <v-menu close-on-click close-on-content-click offset-y v-if="!$isMobile">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-on="on"
          right
          top
          absolute
          v-bind="attrs"
          depressed
          v-if="!isCard"
          :loading="is_working"
          class="admin-top-button"
        >
          <v-icon>$vuetify.icons.dotsHorizontal</v-icon>
        </v-btn>

        <v-btn
          color="secondary"
          fab
          x-small
          dark
          top
          right
          v-bind="attrs"
          v-on="on"
          absolute
          v-if="isCard"
          class="mt-6 mr-n2 edit-btn"
        >
          <v-icon small>$vuetify.icons.fasPen</v-icon>
        </v-btn>
      </template>
      <v-list class="admin-list-menu">
        <v-list-item @click="item.action" v-for="(item, i) in menu" :key="i">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-bottom-sheet v-model="sheet" v-if="$isMobile">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-on="on"
          right
          top
          absolute
          v-bind="attrs"
          depressed
          v-if="!isCard"
          :loading="is_working"
          class="admin-top-button"
        >
          <v-icon>$vuetify.icons.dotsHorizontal</v-icon>
        </v-btn>

        <v-btn
          color="secondary"
          fab
          x-small
          dark
          top
          right
          v-bind="attrs"
          v-on="on"
          absolute
          v-if="isCard"
          class="mt-6 mr-n2 edit-btn"
        >
          <v-icon small>$vuetify.icons.fasPen</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="item.action" v-for="(item, i) in menu" :key="i">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>

    <template v-if="is_admin">
      <avatar-dialog ref="avatarDialog"></avatar-dialog>
      <banner-dialog ref="bannerDialog"></banner-dialog>
      <children-dialog
        ref="childrenDialog"
        v-if="media.can_tag"
        v-on:updated="onChildrenUpdated"
      ></children-dialog>
      <date-dialog ref="editDate" v-on:updated="onUpdated"></date-dialog>
      <edit-dialog
        v-if="media.can_edit"
        v-on:updated="onUpdated"
        ref="editDialog"
      ></edit-dialog>

      <photo-editor
        v-on:close="onEditClose"
        v-on:done="onEditDone"
        v-if="show_editor && media.can_modify"
        :image="media.image.original"
      ></photo-editor>

      <rotate-dialog
        v-on:modified="onModified"
        ref="rotateDialog"
        :image="media.image"
        :id="media.id"
      ></rotate-dialog>
    </template>
  </div>
</template>

<i18n>
    {
    "en": {
      "EditInformation": "Edit information",
      "EditPhoto": "Photo editor",
      "Delete": "Delete photo",
      "ConfirmDeleteMedia": "Are you sure you want to delete this media?",
      "ImageUpdated": "Image updated!",
      "SuccessDelete": "Image deleted!",
      "ChangeDate": "Change date",
      "MakeAlbumCover": "Make album cover",
      "SuccessChangeCover": "Cover photo has been updated!",
      "MakeProfilePicture": "Make diary profile picture",
      "MakeDiaryCover": "Make diary cover photo",
      "TagChildren": "Tag children",
      "RotatePhoto": "Rotate photo"
    },
    "sv": {
      "EditInformation": "Redigera information",
      "EditPhoto": "Bildeditor",
      "Delete": "Ta bort bild",
      "ConfirmDeleteMedia": "Är du säker på att du vill ta bort detta media?",
      "ImageUpdated": "Bilden sparades!",
      "SuccessDelete": "Bilden togs bort!",
      "ChangeDate": "Ändra datum",
      "MakeAlbumCover": "Använd som omslagsbild för album",
      "SuccessChangeCover": "Omslagsbilden har uppdaterades!",
      "MakeProfilePicture": "Använd som profilbild",
      "MakeDiaryCover": "Använd som omslagsbild",
      "TagChildren": "Tagga barn",
      "RotatePhoto": "Rotera bild"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import EditDialog from "../../../components/diary/dialogs/EditMediaInfoDialog.vue";
import EditDateDialog from "../../../components/diary/dialogs/EditDateDialog.vue";
import AvatarDialog from "../../../components/dialogs/AvatarDialog.vue";
import BannerDialog from "../../../components/dialogs/BannerDialog.vue";
import ChildrenSelectDialog from "../../../components/diary/dialogs/ChildrenSelectDialog.vue";
import ImageRotateDialog from "../../../components/diary/dialogs/ImageRotateDialog.vue";
const PhotoEditor = () => import("../../../components/global/PhotoEditor.vue");
import { albumsService } from "../../../_services";

export default {
  name: "diary_media_menu",
  props: {
    media: {
      type: Object,
      required: true,
    },
    isCard: {
      type: Boolean,
      default: false,
    },
    showAlbumCover: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "edit-dialog": EditDialog,
    "children-dialog": ChildrenSelectDialog,
    "avatar-dialog": AvatarDialog,
    "banner-dialog": BannerDialog,
    "date-dialog": EditDateDialog,
    "photo-editor": PhotoEditor,
    "rotate-dialog": ImageRotateDialog,
  },
  data: () => ({
    is_working: false,
    show_editor: false,
    menu: [],
    sheet: false,
  }),
  created: function () {
    if (this.media.can_edit && this.is_admin) {
      this.menu.push({
        title: this.$t("EditInformation"),
        icon: "$vuetify.icons.fileDocumentEditOutline",
        action: this.openEditDescription,
      });
    }
    if (this.media.can_backdate && this.is_admin) {
      this.menu.push({
        title: this.$t("ChangeDate"),
        icon: "$vuetify.icons.calendarRangeOutline",
        action: this.openEditDate,
      });
    }
    if (this.media.can_tag && this.is_admin) {
      this.menu.push({
        title: this.$t("TagChildren"),
        icon: "$vuetify.icons.baby",
        action: this.openChildrenDialog,
      });
    }
    if (this.media.can_modify && this.is_admin && !this.$isMobile) {
      this.menu.push({
        title: this.$t("EditPhoto"),
        icon: "$vuetify.icons.imageEditOutline",
        action: this.editPhoto,
      });
    }
    if (this.media.can_modify && this.is_admin) {
      this.menu.push({
        title: this.$t("RotatePhoto"),
        icon: "$vuetify.icons.rotate3d",
        action: this.rotatePhoto,
      });
    }
    if (this.is_admin && this.showAlbumCover) {
      this.menu.push({
        title: this.$t("MakeAlbumCover"),
        icon: "$vuetify.icons.accountBoxMultipleOutline",
        action: this.makeAlbumCover,
      });
    }
    if (this.is_admin) {
      this.menu.push({
        title: this.$t("MakeProfilePicture"),
        icon: "$vuetify.icons.accountCircleOutline",
        action: this.makeDiaryProfilePicture,
      });
    }

    if (this.is_admin) {
      this.menu.push({
        title: this.$t("MakeDiaryCover"),
        icon: "$vuetify.icons.imageOutline",
        action: this.makeDiaryCover,
      });
    }

    if (this.is_admin && this.media.can_delete) {
      this.menu.push({
        title: this.$t("Delete"),
        icon: "$vuetify.icons.trashCanOutline",
        action: this.deleteMedia,
      });
    }
  },

  methods: {
    ...mapActions({
      deletePhoto: "diary/albums/deleteMedia",
      changeAlbumCover: "diary/albums/changeAlbumCover",
    }),
    openEditDate() {
      this.$refs.editDate.editMediaDate(this.media);
    },
    openChildrenDialog() {
      this.$refs.childrenDialog.openMedia(this.media);
    },
    makeAlbumCover() {
      var self = this;

      self.is_working = true;
      self
        .changeAlbumCover({ album_id: self.media.album_id, media: self.media })
        .then(function () {
          self.$successNoty(self.$t("SuccessChangeCover"));
          self.is_working = false;
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          self.is_working = false;
        });
    },
    makeDiaryProfilePicture() {
      this.$refs.avatarDialog.openDiaryAvatarByUrl(
        this.diary,
        this.media.image.original,
        this.media.id
      );
    },
    makeDiaryCover() {
      this.$refs.bannerDialog.openDiaryBannerByUrl(
        this.diary,
        this.media.image.original,
        this.media.id
      );
    },
    async deleteMedia() {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteMedia"));

      if (res) {
        self.is_working = true;

        self
          .deletePhoto(self.media.id)
          .then(function () {
            self.$successNoty(self.$t("SuccessDelete"));
            self.$emit("deleted", self.media);
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            self.is_working = false;
          });
      }
    },
    openEditDescription() {
      this.$refs.editDialog.editMedia(this.media);
    },
    onUpdated(media) {
      this.$emit("updated", media);
    },
    onChildrenUpdated(media) {
      this.$emit("updated", media);
    },

    editPhoto() {
      this.show_editor = true;
    },
    onEditDone(data) {
      var self = this;
      self.is_working = true;
      albumsService
        .uploadEditedImage(self.media.id, data.blob)
        .then(function (response) {
          self.is_working = false;
          self.$emit("modified", response.data.image);
          self.$successNoty(self.$t("ImageUpdated"));
          this.show_editor = false;
        })
        .catch(function (error) {
          self.is_working = false;
          self.$ajaxErrorNoty(error);
        });
    },
    onEditClose() {
      this.show_editor = false;
    },
    rotatePhoto() {
      this.$refs.rotateDialog.open();
    },
    onModified(image) {
      this.$emit("modified", image);
    },
  },
};
</script>

<style scoped>
.edit-btn {
  opacity: 0.9;
}
</style>