<template>
    <div class="text-break text-clamp-row">
        <v-clamp autoresize :max-lines="rows">{{ text }}<button v-if="expanded || clamped"
                              slot="after"
                              slot-scope="{ toggle, expanded, clamped }"
                              class="read-toggle"
                              @click="toggle">
                <template v-if="clamped">
                    &nbsp;{{ expand_text }}
                </template>
                <template v-if="expanded">
                    &nbsp;
                </template>
            </button>
        </v-clamp>
    </div>
</template>

<i18n>
    {
    "en": {
    "ShowMore": "Show more"
    },
    "sv": {
    "ShowMore": "Visa mer"
    }
    }
</i18n>

<script>
    import VClamp from 'vue-clamp'

    export default {
        props: {
            text: {
                type: String,
                default: ""
            },
            expand: {
                type: String,
                default: ''
            },
            collaps: {
                type: String,
                default: ''
            },
            rows: {
                type: Number,
                default: 2
            }
        },
        data() {
            return {
                collaps_text: this.collaps
            }
        },
        created() {
            if (!this.collaps) {
                this.expand_text = this.$t('ShowMore');
            }
        },
        components: {
            VClamp
        }
    }
</script>

<style scoped>
    .my-clamp span span {
        white-space: pre-line;
    }

    .my-clamp {
        word-break: break-word;
    }
    .text-clamp-row button {
        color:#1976d2;
        margin-left: 5px;
    }
</style>