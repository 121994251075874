<template>
  <span>
    <v-tooltip top v-if="privacy == $enums.PRIVACY.All">
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on">$vuetify.icons.earth</v-icon>
      </template>
      <span>{{ $t("Public") }}</span>
    </v-tooltip>
    <v-tooltip top v-if="privacy == $enums.PRIVACY.Members">
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on"
          >$vuetify.icons.accountMultiple</v-icon
        >
      </template>
      <span>{{ $t("Members") }}</span>
    </v-tooltip>
    <v-tooltip top v-if="privacy == $enums.PRIVACY.Private">
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on">$vuetify.icons.lock</v-icon>
      </template>
      <span>{{ $t("Private") }}</span>
    </v-tooltip>
  </span>
</template>

<i18n>
    {
    "en": {
    "Public": "Public",
    "Members": "Members",
    "Private": "Only me"
    },
    "sv": {
    "Public": "Publik",
    "Members": "Inloggade",
    "Private": "Privat"
    }
    }
</i18n>


<script>
export default {
  name: "privacy-icon",
  props: {
    privacy: {
      type: Number,
      default: 1,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>